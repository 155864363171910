var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.cssType == 1 ? "css-type1" : "css-type2" }, [
    _vm.wechat
      ? _c(
          "div",
          {
            staticClass: "select-item f fc wechat",
            on: {
              click: function ($event) {
                return _vm.handleClick("wechat")
              },
            },
          },
          [
            _vm._m(0),
            _vm.wechatLoading
              ? _c("van-loading", { attrs: { type: "spinner", size: "24" } })
              : [
                  _vm.cssType === 1
                    ? _c("van-icon", {
                        staticClass: "right-arrow",
                        attrs: { name: "arrow" },
                      })
                    : _vm._e(),
                ],
          ],
          2
        )
      : _vm._e(),
    _vm.alipay
      ? _c(
          "div",
          {
            staticClass: "select-item f fc alipay",
            on: {
              click: function ($event) {
                return _vm.handleClick("alipay")
              },
            },
          },
          [
            _vm._m(1),
            _vm.alipayLoading
              ? _c("van-loading", { attrs: { type: "spinner", size: "24" } })
              : [
                  _vm.cssType === 1
                    ? _c("van-icon", {
                        staticClass: "right-arrow",
                        attrs: { name: "arrow" },
                      })
                    : _vm._e(),
                ],
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-way f fc" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/951c7d88ff5302-47x47.png",
        },
      }),
      _vm._v("微信支付 "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pay-way f fc" }, [
      _c("img", {
        staticClass: "icon",
        attrs: {
          src: "https://img-fe.tengzhihh.com/image/134b410b4a030a-47x47.png",
        },
      }),
      _vm._v("支付宝 "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }