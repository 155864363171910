<template>
  <div :class="cssType == 1 ? 'css-type1' : 'css-type2'">
    <div class="select-item f fc wechat" @click="handleClick('wechat')" v-if="wechat">
      <div class="pay-way f fc">
        <img src="https://img-fe.tengzhihh.com/image/951c7d88ff5302-47x47.png" class="icon" />微信支付
      </div>
      <van-loading type="spinner" size="24" v-if="wechatLoading" />
      <template v-else>
        <van-icon name="arrow" v-if="cssType === 1" class="right-arrow" />
      </template>
    </div>
    <div class="select-item f fc alipay" @click="handleClick('alipay')" v-if="alipay">
      <div class="pay-way f fc">
        <img src="https://img-fe.tengzhihh.com/image/134b410b4a030a-47x47.png" class="icon" />支付宝
      </div>
      <van-loading type="spinner" size="24" v-if="alipayLoading" />
      <template v-else>
        <van-icon name="arrow" v-if="cssType === 1" class="right-arrow" />
      </template>
    </div>
    <!-- <div class="select-item f fc virtual-coin" @click="handleClick('coin')" v-if="virtual">
      <div class="pay-way f fc">
        <img src="https://img-fe.tengzhihh.com/image/340376cac80e1b-55x55.png" class="icon" /><span class="virtual"
          >虚拟币支付</span
        >
      </div>
      <div class="f fc">
        <span class="virtual-amount">
          {{ userCoins }}
        </span>
        <van-loading type="spinner" size="24" v-if="coinLoading" />
        <template v-else>
          <van-icon name="arrow" v-if="cssType === 1" class="right-arrow" />
        </template>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import apiPath from '@/utils/apiPath';
import tools from '@/utils/tools';
import { setStore } from '@/utils/localStore';
export default {
  props: {
    cssType: { type: [String, Number], default: 1 },
    goodsId: { type: [String, Number], default: 0 },
    failedTip: { type: String, default: '商品已下架' },
    from: { type: String, default: '' },
    userCoins: {
      type: [String, Number],
      default: 0,
    },

    virtual: {
      type: Boolean,
      default: false,
    },
    serialId: {
      type: String,
      default: '',
    },
    packageId: {
      type: [String, Number],
      default: 0,
    },
    packages: {
      type: Array,
      default: () => [],
    },
    customParams: {
      type: Object,
      default: () => {},
    },
    goodsItem: {
      type: Object,
      default: () => {
        return { category: 'virtual' }; //默认虚拟商品
      },
    },
  },
  data() {
    return {
      orderId: '',
      wechatLoading: false,
      alipayLoading: false,
      coinLoading: false,
    };
  },
  computed: {
    ...mapState(['env']),
    returnUrl: function () {
      const mainUrl = location.origin;
      if (this.from === 'wallet') {
        return `${mainUrl}/my/wallet`;
      } else if (this.from === 'goodsDetail') {
        return `${mainUrl}/shop/paySuccess`;
      }
    },
    alipay() {
      return !tools.isWeChat();
    },
    wechat() {
      return tools.isWeChat();
    },
  },
  mounted() {},
  unMounted() {
    this.wechatLoading = false;
    this.alipayLoading = false;
    this.coinLoading = false;
  },
  methods: {
    handleClick(type) {
      if (this.goodsItem.category === 'entity' && !this.customParams.goods_num) {
        return this.$toast('请先选择购买数量');
      }
      if (this.goodsItem.category === 'entity' && !this.customParams.address_id) {
        return this.$toast('请先选择收货地址');
      }
      if (this.goodsItem.category !== 'entity' && !this.serialId) {
        return this.$toast('请先选择神台机');
      }
      // 防止重复点击
      if (this.wechatLoading || this.alipayLoading || this.coinLoading) {
        return;
      }
      this[`${type}Loading`] = true;
      this.createOrder({
        goods_id: this.goodsId,
        payment_mode: type,
        return_url: this.returnUrl,
        serial_id: this.serialId,
        package_id: this.packageId,
        packages: this.packages,
      });
    },
    createOrder({ goods_id, payment_mode, return_url, serial_id = '', package_id = 0, packages = [] }) {
      if (!goods_id) {
        return this.$toast(this.failedTip);
      }
      if (packages.length > 0 && !package_id) {
        return this.$toast('请先选择类型');
      }

      const data = {
        goods_id,
        payment_mode,
        serial_id,
        platform: this.wechat ? 'wechat' : 'web',
        ver: '1.2',
      };
      package_id && (data['package_id'] = package_id);
      return_url && payment_mode !== 'coin' && (data['return_url'] = return_url);
      if (this.goodsItem.category === 'entity') {
        data.goods_num = this.customParams.goods_num;
        data.address_id = this.customParams.address_id;
      }
      this.$http
        .post(apiPath[this.goodsItem.category === 'entity' ? 'createEntityOrder' : 'createOrder'], data)
        .then(res => {
          if (res.data.code == 401003) {
            this.$emit('insufficientBalance');
          } else if (res.code === 0) {
            if (payment_mode === 'coin') {
              this.$router.replace(`/shop/paySuccess?order_id=${res.data.order_id}`);
            } else {
              this.orderId = res.data.order_center_id;
              const jumpUrl = this.spliceUrl(payment_mode, res.data);
              jumpUrl && (location.href = jumpUrl);
            }
          }
        });
    },
    spliceUrl(payment_mode, response) {
      setStore({
        name: 'routePrevious',
        content: this.$route.path,
      });
      if (payment_mode === 'alipay') {
        return `https://${response.default}/pay?order_id=${response.order_center_id}&channel_id=${response.ali_pay_channel}`;
      } else if (payment_mode === 'wechat') {
        if (this.$tools.isWeChat()) {
          return `https://${response.wechatv3_official}/pay?order_id=${response.order_center_id}&channel_id=${response.wechat_office_pay_channel}`;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.css-type1 {
  .select-item {
    font-weight: 600;
    margin-bottom: 20px;
    justify-content: space-between;
    .pay-way {
      font-size: 15px;
      color: #333333;
      .icon {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }
  }
  .select-item:last-child {
    margin: 0;
  }
  .virtual {
    color: #e69d17;
  }
  .virtual-amount {
    font-size: 15px;
    color: #e69d17;
  }
  .right-arrow {
    font-size: 14px;
    font-weight: bold;
    color: #b5b5b5;
  }
}
.css-type2 {
  .select-item {
    width: 329px;
    height: 43px;
    background: #6cc142;
    border-radius: 21px;
    color: #fff;
    margin-bottom: 11px;
    font-size: 15px;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .wechat {
    font-weight: 600;
    background-color: #6cc142;
  }
  .alipay {
    font-weight: 600;
    background-color: #01abf0;
  }
  .virtual-coin {
    background: linear-gradient(10deg, #ffe79f 0%, #ffda77 100%);
    color: #804e00;
    font-weight: 600;
  }
  .select-item:last-child {
    margin: 0;
  }

  .virtual {
    color: #e69d17;
  }
  .virtual-amount {
    font-size: 15px;
    color: #e69d17;
  }
  .right-arrow {
    font-size: 14px;
    font-weight: bold;
    color: #b5b5b5;
  }
}
</style>
